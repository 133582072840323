const storePrefix = 'YZYX_CRM_';

export const setStore = (name, content) => {
    if(!name) return;
    if(typeof content !== 'string') {
        content = JSON.stringify(content);
    }
    window.localStorage.setItem(storePrefix + name, content);
};
// 获取localStorage
export const getStore = name => {
    if(!name) return;
    return window.localStorage.getItem(storePrefix + name);
};

// 删除localStorage
export const removeStore = name => {
    if(!name) return;
    window.localStorage.removeItem(storePrefix + name);
};