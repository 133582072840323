<template>
    <div class="className">
        <span v-if="isSecondPage" class="iconBack">
            <el-tooltip class="item" effect="dark" content="回到上一页" placement="bottom">
                <i class="el-icon-back  classNameIcon" @click="goBack" ></i>
            </el-tooltip>
        </span>
        <span class="classCode">{{classCode}}</span>
        <span>{{courseName}}</span>
        <span v-if="!isSecondPage && $route.name !== 'home' && $route.name !== 'realTimeDetails'">
            <span v-if="$route.name === 'attendClass'">
                <el-tooltip class="item" effect="dark" content="学员签到" placement="bottom">
                    <i class="el-icon-document  classNameIcon" @click="goRollCall"></i>
                </el-tooltip>
            </span>
            <span v-if="isMusic === 1">
                <el-tooltip class="item" effect="dark" content="课堂重点" placement="bottom">
                    <i class="el-icon-data-analysis  classNameIcon" @click="goClassroomFocus"></i>
                </el-tooltip>
            </span>
            <span v-if="isMusic === 1">
                <el-tooltip class="item" effect="dark" content="拓展课" placement="bottom">
                    <i class="el-icon-film  classNameIcon" @click="goExtendCourse"></i>
                </el-tooltip>
            </span>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'courseClassInfo',
        props: {
            courseName: {
                type: String,
                default: ''
            },
            classCode: {
                type: String,
                default: ''
            },
            isMusic: {
                type: Number,
                default: 0
            },
            paikeId: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                isFullScreen: false,
                overClass: this.$route.query.overClass ? 1 : 0,
            };
        },
        computed: {
            isSecondPage(){
                return this.$route.name === 'classroomFocus' || this.$route.name === 'extendCourse'
            },
        },
        created() {
        },
        methods: {
            goBack(){
                this.$router.go(-1)
            },
            goRollCall(){
                if(this.$route.name === 'attendClass'){
                    this.$router.replace({name: 'checkWork'})
                }
            },
            goClassroomFocus(){
                this.$router.push({name: 'classroomFocus'})
            },
            goExtendCourse(){
                this.$router.push({name: 'extendCourse'})
            }
        },
    };
</script>
<style lang="less" scoped>
    .className{
        font-size: 30px;
        font-family: Noto Sans S Chinese;
        font-weight: 400;
        color: #FFFCFD;
        line-height: 90px;
        margin-left: 20px;
        .classNameIcon{
            display: inline-block;
            margin-left: 30px;
            cursor: pointer;;
        }
        .classCode{
            display: inline-block;
            margin-right: 30px;
            margin-left: 30px;
        }
        .iconBack{
            display: inline-block;
            font-size: 35px;
        }
    }
</style>
