<template>
    <div 
        v-if="showFullScreenBtn" 
        class="full-screen-btn-con" 
        @click="handleChange">
        <el-tooltip 
            :content="value ? '退出全屏' : '全屏'" 
            placement="bottom">
            <i class="el-icon-full-screen  fullIcon"></i>
        </el-tooltip>
    </div>
</template>

<script>
    export default {
        name: 'FullScreen',
        props: {
            value: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            showFullScreenBtn() {
                return window.navigator.userAgent.indexOf('MSIE') < 0;
            }
        },
        watch:{
            value(val) {
                if(val){
                    this.$store.commit('SETFULLSCREEN', 1);
                    localStorage.isFullscreen = 1;
                }else{
                    this.$store.commit('DELETEFULLSCREEN');
                    localStorage.isFullscreen = null;
                }
            },
        },
        created() {
            let isFullscreen = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
            isFullscreen = !!isFullscreen;
            document.addEventListener('fullscreenchange', () => {
                this.$emit('input', !this.value);
                this.$emit('on-change', !this.value);
            });
            document.addEventListener('mozfullscreenchange', () => {
                this.$emit('input', !this.value);
                this.$emit('on-change', !this.value);
            });
            document.addEventListener('webkitfullscreenchange', () => {
                this.$emit('input', !this.value);
                this.$emit('on-change', !this.value);
            });
            document.addEventListener('msfullscreenchange', () => {
                this.$emit('input', !this.value);
                this.$emit('on-change', !this.value);
            });
            this.$emit('input', isFullscreen);
        },
        methods: {
            handleFullscreen() {
                let main = document.body;
                if(this.value) {
                    if(document.exitFullscreen) {
                        document.exitFullscreen();
                    }else if(document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    }else if(document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    }else if(document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                    this.$store.commit('DELETEFULLSCREEN');
                    localStorage.isFullscreen = null;
                }else {
                    if(main.requestFullscreen) {
                        main.requestFullscreen();
                    }else if(main.mozRequestFullScreen) {
                        main.mozRequestFullScreen();
                    }else if(main.webkitRequestFullScreen) {
                        main.webkitRequestFullScreen();
                    }else if(main.msRequestFullscreen) {
                        main.msRequestFullscreen();
                    }
                    this.$store.commit('SETFULLSCREEN', 1);
                    localStorage.isFullscreen = 1;
                    
                }
            },
            handleChange() {
                this.handleFullscreen();
            }
        },
    };
</script>
<style lang="less" scoped>
   .fullIcon{
       display: inline-block;
       font-size: 30px;
       color: #fff;
   }
   .full-screen-btn-con{
       display: inline-block;
       margin-right: 20px;
       cursor: pointer;
   }
</style>
