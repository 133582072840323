import { getStore } from './../util';

const user = {
    state: {
        name: getStore('name') ? getStore('name') : null, // 用户 name
        token: getStore('token') ? getStore('token') : null, // 用户 token
        currentRole: getStore('currentRole') ? getStore('currentRole') : null, // 用户当前角色
        userId: getStore('userId') ? getStore('userId') : null, // 用户Id
        courseName: getStore('courseName') ? getStore('courseName') : null, // 用户当前课程
        classCode: getStore('courseName') ? getStore('courseName') : null, // 用户当前班级代码
        isFullscreen: getStore('isFullscreen') ? getStore('isFullscreen') : null, // 是否全屏
        currentTime: getStore('currentTime') ? getStore('currentTime') : null, // 网络时间
        paikeId: getStore('paikeId') ? getStore('paikeId') : null, //排课Id
        teacherId: getStore('teacherId') ? getStore('teacherId') : null, //TEACHERId
        docmentVisibility: false,
    },
    mutations: {
        // 设置 name
        SETNAME(state, params) {
            state.name = params;
        },
        // 设置 token
        SETTOKEN(state, params) {
            state.token = params;
        },
        // 设置当前角色
        SETCURRENTROLE(state, params) {
            state.currentRole = params;
        },
        // 设置用户ID
        SETUSERID(state, params) {
            state.userId = params;
        },
        // 设置当前课程
        SETCOURSENAME(state, params) {
            state.courseName = params;
        },
        // 设置当前班级代码
        SETCLASSCODE(state, params) {
            state.classCode = params;
        },
        // 设置全屏
        SETFULLSCREEN(state, params) {
            state.isFullscreen = params;
        },
        // 设置是网络时间
        SETCURRENTTIME(state, params) {
            state.currentTime = params;
        },
        // 设置排课Id
        SETPAIKEID(state, params) {
            state.paikeId = params;
        },
        // 设置teacherId
        SETTEACHERID(state, params) {
            state.teacherId = params;
        },
        // 设置当前页面是否隐藏
        SET_DOCUMENT_VISIBILITY: (state, docmentVisibility) => {
            state.docmentVisibility = docmentVisibility
        },
        // 删除 name
        DELETENAME(state) {
            state.name = null;
        },
        // 删除 token
        DELETETOKEN(state) {
            state.token = null;
        },
        // 删除当前角色
        DELETECURRENTROLE(state) {
            state.allRole = null;
        },
        // 删除userId
        DELETEUSERID(state){
            state.userId = null;
        },
        // 删除当前课程
        DELETECOURSENAME(state){
            state.classCode = null;
        },
        // 删除当前课程
        DELETECLASSCODE(state){
            state.courseName = null;
        },
        // 取消全屏
        DELETEFULLSCREEN(state){
            state.isFullscreen = null;
        },
        // 删除网络时间
        DELETECURRENTTIME(state) {
            state.currentTime = null;
        },
        // 删除排课Id
        DELETEPAIKEID(state) {
            state.paikeId = null;
        },
        // 删除teacherId
        DELETETEACHERID(state) {
            state.teacherId = null;
        },
        logout() {
            localStorage.clear();
        },
    }
};

export default user;
