import Vue from "vue";

import router from "./router/index";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from "./App.vue";
import Viewer from 'v-viewer'; // 这个是图片放大功能
import 'viewerjs/dist/viewer.css'; // 这个是图片放大功能样式
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import './util/rem'
import WSconfig from './http/config';
import * as mutationTypes from '@/store/mutation-types';
import VueWebsocket from 'vue-native-websocket';
Vue.use(VideoPlayer)

Vue.use(ElementUI);
// 图片放大
Vue.use(Viewer, {
    defaultOptions: {
        movable: true,
        interval: 3000,
        zIndex: 9999
    }
});
// use websocket plugin
Vue.use(VueWebsocket, WSconfig.websocketConfig.connection, {
    // vuex store instance
    store,
    // vuex store mutation types
    mutations: mutationTypes,
    // data format within JSON
    format: WSconfig.websocketConfig.format,
    // connect ws manaually
    connectManually: WSconfig.websocketConfig.connectManually,
    // reconnect or not
    reconnection: WSconfig.websocketConfig.reconnection,
    // the attempt times of reconnection
    reconnectionAttempts: WSconfig.websocketConfig.reconnectionAttempts,
    // the moment between two near attempts
    reconnectionDelay: WSconfig.websocketConfig.reconnectionDelay
});
new Vue({
  router: router,
  store,
  methods: {
    // 根据分辨率改变屏幕放大比例
    changeIpx() {
        let w = window.screen.width || 1920;
        let newIpx = 1;
        if(w <= 1366) {
            newIpx = 0.8;
        }
        if(w > 1366 && w <= 1680) {
            newIpx = 1.1;
        }
        if(w > 1680) {
            newIpx = 1.15;
        }
        document.body.style.zoom = newIpx;
    }
},
  render: h => h(App)
}).$mount("#app");
