const env = process.env.NODE_ENV;
const isDev = (env === 'development');
const isXpre = (env === 'xpre')

// 服务器测试地址
const serverTestUrl = 'https://localgateway.chinayzyx.com:4834';


// 新预发布地址
const serverNewBateUrl = 'https://xpregateway.chinayzyx.com:4836';
// 【正式环境放开】
const serverFormalUrl = 'https://gw.chinayzyx.com';
// const serverFormalUrl = 'https://gw.ahyzyx.com';
// pre  websocket
const WSUrlForPre = 'wss://prewebsocket.chinayzyx.com:4836/websocket/ws';
const WSUrlForxPre = 'wss://xprewebsocket.chinayzyx.com:4836/websocket/ws';
const WSUrlForProd = 'wss://websocket.chinayzyx.com/websocket/ws';

const output = isDev ? {
    httpConfig: {
        baseURL: '/api',
        timeout: 15000,
        severURL: serverTestUrl
    },
    websocketConfig: {
        connection: WSUrlForPre,
        format: 'json',
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 3000,
        connectManually: true
    }
} : isXpre ? {
    httpConfig: {
        baseURL: serverNewBateUrl,
        timeout: 15000,
        severURL: serverNewBateUrl
    },
    websocketConfig: {
        connection: WSUrlForxPre,
        format: 'json',
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 3000,
        connectManually: true
    }
} : {
    httpConfig: {
        baseURL: serverFormalUrl,
        timeout: 15000,
        severURL: serverFormalUrl
    },
    websocketConfig: {
        connection: WSUrlForProd,
        format: 'json',
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 3000,
        connectManually: true
    }
};

export default output;
