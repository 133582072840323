import Vue from "vue";
import Router from "vue-router";
import Main from '@/views/Main.vue';
import Cookies from 'js-cookie';
import { getStore } from '../util';

Vue.use(Router);

const routers = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Login - 登录'
  },
  component: () => import('@/views/login')
  },
  {
    path: '/locking', // 老师锁
    name: 'locking',
    component: () => import('@/views/main-components/lockscreen/components/locking-page.vue')
  },
  {
    path: '/guidePage', // 中转
    name: 'guidePage',
    component: () => import('@/views/guidePage/index.vue')
  },
  {
    path: '/',
    name: 'home',
    redirect: '/home',
    component: Main,
    children: [
      { path: '/home', name: 'home', component: () => import('@/views/home/home.vue') }, // 首页
      { path: 'checkWork', name: 'checkWork', component: () => import('@/views/checkWork/index.vue') }, // 学员签到
      { path: 'realTimeDetails', name: 'realTimeDetails', component: () => import('@/views/realTimeDetails/index.vue') }, // 细则详情
      { path: 'attendClass', name: 'attendClass', component: () => import('@/views/attendClass/index.vue') }, // 上课
      { path: 'classroomFocus', name: 'classroomFocus', component: () => import('@/views/classroomFocus/index.vue') }, // 课堂重点
      { path: 'extendCourse', name: 'extendCourse', component: () => import('@/views/extendCourse/index.vue') }, // 拓展课
    ]
  }
];

const RouterConfig = {
  // mode: 'history',
  routes: routers
};

const router = new Router(RouterConfig);
export default router;
router.beforeEach((to, from, next) => {
  if(Cookies.get('locking') === '1' && to.name !== 'locking') { // 判断当前是否是锁定状态
      next({
          replace: true,
          name: 'locking'
      });
  }else if(Cookies.get('locking') === '0' && to.name === 'locking') {
      next(false);
  }else {
      if(!getStore('token') && to.name !== 'login') { // 判断是否已经登录且前往的页面不是登录页
          next({
              name: 'login'
          });
      }else if(getStore('token') && to.name === 'login') { // 判断是否已经登录且前往的是登录页
          next({
              name: 'home'
          });
      }else if(!getStore('token') && to.name === 'login') {
          next();
      }else{
        next();
      }
  }
});
router.afterEach((to, from) => {
  console.log(to,from)
  window.scrollTo(0, 0);
});
