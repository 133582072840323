/* eslint-disable no-console */
import { setStore } from './../util';
import { user } from './../api/index.js';

import  router  from './../router';
import { Notification } from 'element-ui';
const actions = {
    // 登陆
    login({commit}, params) {
        return new Promise((resolve, reject) => {
            user.login(params, res => {
                if(res.status === 1) {
                    let isTeacher = res.data.roles.some(item => {
                        return item.roleId === '2629167436489461c11962d738c0e5ba'
                    })
                    if(!isTeacher){
                        return resolve({type: 1});
                    }
                    setStore('gender', res.data.gender);
                    commit('SETNAME', res.data.name);
                    setStore('name', res.data.name);
                    commit('SETTOKEN', res.data.token);
                    setStore('token', res.data.token);
                    commit('SETCURRENTROLE', res.data.roles[0].roleId);
                    setStore('currentRole', res.data.roles[0].roleId);
                    commit('SETUSERID', res.data.userId);
                    setStore('userId', res.data.userId);
                    commit('SETTEACHERID', res.data.teacherId);
                    setStore('teacherId', res.data.teacherId);
                    resolve(res);
                }else {
                    reject(res);
                }
            });
        });
    },
    logout({commit}, params) {
        // 清除当前store中的用户状态
        commit('DELETENAME');
        commit('DELETETOKEN');
        commit('DELETECURRENTROLE');
        commit('DELETEUSERID');
        commit('DELETECOURSENAME');
        commit('DELETEFULLSCREEN');
        commit('logout');
        router.push({name: 'login'});
        user.logout(params, res => {
            if(res.status === 1) {
                Notification({
                    title: '退出成功~',
                    type: 'success'
                  });
            }else {
                Notification.error({
                    title: '退出失败,请重新尝试~',
                  });
            }
        });
    },
};

export default actions;
