import Vue from 'vue';
import Vuex from 'vuex';

import user from './user';
import getters from './getters';
import actions from './actions';
import message from './modules/message';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        //
    },
    mutations: {
        //
    },
    actions,
    modules: {
        user,message
    },
    getters
});

export default store;

