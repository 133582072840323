<template>
  <div>
    <div class="signout-btn-con" @click="signOut">
      <el-tooltip content="退出登录" placement="bottom">
        <i class="el-icon-switch-button signIcon"></i>
      </el-tooltip>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="0%"
    >
      <div class="checkSucCard">
        <div class="checkSuc">
          <div class="tip"></div>
          <div class="close" @click="close" v-if="!finalDialogVisible"></div>
          <div class="sucClassTip">
            <div class="sucTipMessage">
              <div class="stopClassTipOne">请确认需要进行下面哪项操作？</div>
              <div class="stopClassTipTwo">
                <div class="words">若无法下课，请联系学管师确认考勤！</div>
              </div>
            </div>
          </div>
          <div class="operation">
            <div
              :class="hasCourse ? 'stopCourse' : 'contOp'"
              @click="stopCourse"
            >
              下课并回顾
            </div>
            <!-- <div class="stopCourse" @click="stopCourse">下课并回顾</div> -->
            <div class="sure" @click="toSignOutClass">退出账号</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="finalDialogVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="0%"
    >
      <div class="checkSucCard">
        <div class="checkSuc">
          <div class="tip"></div>
          <div class="sucClassTip">
            <div class="sucTipMessage">
              <div class="stopClassTipOne">{{ tipTitle }}</div>
            </div>
          </div>
          <div class="operation">
            <div class="isClassOver" @click="sure" v-loading="loading">是</div>
            <div class="cancel" @click="cancel">否</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="StopCourseTipDialogVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="0%"
    >
      <div class="checkSucCard">
        <div class="checkSuc">
          <div class="tip"></div>
          <div class="close" @click="closeStopCourseTip"></div>
          <div class="stopCourseTip">
            <div class="sucTipMessage">
              <div class="stopClassTipOne">{{ StopCourseTipMessage }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import { page } from './../../../api/index.js';
    import { Message } from 'element-ui';
    export default {
        name: 'signOut',
        props: {
            value: {
                type: Boolean,
                default: false
            },
            paikeId: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                dialogVisible: false,
                finalDialogVisible: false,
                tipTitle: '请确认是否下课？',
                hasCourse: true,
                loading: false,
                StopCourseTipDialogVisible: false,
                StopCourseTipMessage: '',
            }
        },
        mounted() {
            
        },
        methods: {
            ...mapActions(['logout']),
            // getPaikeId(){
            //     const that = this;
            //     return new Promise(function(resolve,reject){
            //         page.getCurrDayOnClass({}, res => {
            //             if(res.status === 1) {
            //                 if(res.data && res.data.courseName){
            //                     that.paikeId = res.data.paikeId;
            //                     resolve()
            //                 }else{
            //                     reject()
            //                 }   
            //             }else {
            //                 Message.error(res.message);
            //             }
            //         });
            //     })
            // },
            // getPaikeIdForCurrDayLatestClass(){
            //     const that = this;
            //     return new Promise(function(resolve,reject){
            //         page.getCurrDayLatestClassTime({}, res => {
            //             if(res.status === 1) {
            //                 if(res.data && res.data.courseName){
            //                     that.paikeId = res.data.paikeId;
            //                     resolve()
            //                 }else{
            //                     that.hasCourse = false;
            //                     that.dialogVisible = true;
            //                     reject()
            //                 }
            //             }else {
            //                 Message.error(res.message);
            //             }
            //         });
            //     })
            // },
            // getPaikeIdForQuery(){
            //     const that = this;
            //     return new Promise(function(resolve,reject){
            //         if(!that.$route.query.paikeId){
            //             reject()
            //         }else{
            //             page.getCourseInfoByPaikeId(that.$route.query.paikeId, res => {
            //                 if(res.status === 1) {
            //                     if(res.data && res.data.courseName){
            //                         that.paikeId = res.data.paikeId;
            //                         resolve()
            //                     }else{
            //                         reject()
            //                     }
            //                 }else {
            //                     Message.error(res.message);
            //                 }
            //             });
            //         }
            //     })
            // },
            signOut(){
                if(this.$route.name === 'home'){
                    this.hasCourse = false;
                    this.dialogVisible = true;
                }else if(this.$route.name === 'attendClass' || this.$route.name === 'checkWork') {
                    this.hasCourse = true
                    this.dialogVisible = true;
                }else{
                    this.checkGotoPage();
                    // this.getPaikeId().then(() => {
                    //         this.checkGotoPage()
                    // }).catch(() => {
                    //     this.getPaikeIdForQuery().then(() => {
                    //         this.checkGotoPage()
                    //     }).catch(() => {
                    //         this.getPaikeIdForCurrDayLatestClass().then(() => {
                    //             this.checkGotoPage()
                    //         }).catch(() => {})
                    //     });
                    // })
                }
                
            },
            checkGotoPage(){
                let id = this.paikeId  ? this.paikeId : '0';
                 page.goToPage(id, res => {
                        if(res.status === 1) {
                            if(res.data === 'class'){
                                this.hasCourse = true;
                                this.dialogVisible = true;
                            }else{
                                this.hasCourse = false;
                                this.dialogVisible = true;
                            }
                        }else {
                            Message.error(res.message);
                    }
                });
            },
            stopCourse(){
                if(!this.hasCourse) return false
                this.finalDialogVisible = true;
                this.tipTitle = '请确认是否下课？';
            },
            toSignOutClass(){
               this.finalDialogVisible = true;
               this.tipTitle = '请确认是否退出班级？';
            },
            close(){
                this.dialogVisible = false;
            },
            closeStopCourseTip(){
                this.StopCourseTipDialogVisible = false;
                this.dialogVisible = false;
                this.finalDialogVisible = false;
            },
            sure(){
                this.loading = true;
                if(this.tipTitle === '请确认是否下课？'){
                    page.overClass(this.paikeId, res => {
                        if(res.status === 1) {
                            this.loading = false;
                            if(res.data){
                                this.StopCourseTipDialogVisible = true;
                                this.StopCourseTipMessage = res.message;
                            }else{
                                this.dialogVisible = false;
                                this.finalDialogVisible = false;
                                if(this.$route.name === 'realTimeDetails'){
                                    this.$router.replace({name: 'realTimeDetails',query: {overClass: 1}});
                                    this.$router.go(0);
                                }else{
                                    this.$router.replace({name: 'realTimeDetails',query: {overClass: 1}});
                                }
                            }
                        }else {
                            Message.error(res.message);
                        }
                    });
                }else{
                    this.loading = false;
                    this.logout();
                }
                
            },
            cancel(){
                this.dialogVisible = false;
                this.finalDialogVisible = false;
            },
        },
    };
</script>
<style lang="less" scoped>
.signIcon {
  display: inline-block;
  font-size: 30px;
  color: #fff;

  cursor: pointer;
}
.signout-btn-con {
  display: inline-block;
  margin: 0 30px 0 30px;
}
.checkSucCard {
  position: fixed;
  left: 50%;
  top: 48%;
  width: 760px;
  height: 475px;
  background: url("../../../assets/image/bg-login-card.png") center/cover
    no-repeat;
  transform: translateY(-50%) translateX(-50%);
  .checkSuc {
    position: absolute;
    left: 65px;
    top: 40px;
    width: 630px;
    height: 410px;
    background: #fffcfd;
    border-radius: 135px;
    .tip {
      position: absolute;
      left: 50%;
      top: -16%;
      width: 400px;
      height: 110px;
      background: url("../../../assets/image/tip.png") center/cover no-repeat;
      transform: translateY(-50%) translateX(-50%);
    }
    .close {
      position: absolute;
      left: 110%;
      top: -10%;
      width: 50px;
      height: 50px;
      background: url("../../../assets/image/close.png") center/cover no-repeat;
      transform: translateY(-50%) translateX(-50%);
      cursor: pointer;
    }
    .sucTip {
      position: absolute;
      top: 52%;
      width: 100%;
      .sucTipMessage {
        text-align: center;
        font-size: 25px;
        font-family: Noto Sans S Chinese;
        font-weight: 400;
        color: #939393;
        .msgOne {
          letter-spacing: 8px;
        }
        .msgTwo {
          letter-spacing: 8px;
          margin-top: 10px;
        }
        .msgThree {
          word-spacing: 10px;
          margin-top: 30px;
          color: #2cbd74;
        }
        .stopClassTipOne {
          letter-spacing: 8px;
        }
        .beginClassTipTwo {
          letter-spacing: 8px;
        }
      }
    }
    .stopCourseTip {
      position: absolute;
      top: 40%;
      width: 100%;
      .sucTipMessage {
        text-align: center;

        .stopClassTipOne {
          font-size: 30px;
          font-family: Noto Sans S Chinese;
          font-weight: 400;
          color: #939393;
          letter-spacing: 8px;
          margin-bottom: 30px;
        }
        .beginClassTipTwo {
          letter-spacing: 8px;
        }
      }
    }
    .sucClassTip {
      position: absolute;
      top: 20%;
      width: 100%;
      .sucTipMessage {
        text-align: center;
        .stopClassTipOne {
          font-size: 30px;
          font-family: Noto Sans S Chinese;
          font-weight: 400;
          color: #939393;
          letter-spacing: 8px;
          margin-bottom: 30px;
        }
        .stopClassTipTwo {
          display: flex;
          align-items: center;
          justify-content: center;
          .words {
            font-size: 20px;
            font-family: Noto Sans S Chinese;
            font-weight: 400;
            width: 380px;
            color: #e10c0c;
          }
        }
      }
    }
    .operation {
      position: absolute;
      left: 50%;
      top: 75%;
      width: 500px;
      height: 110px;
      transform: translateY(-50%) translateX(-50%);
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      letter-spacing: 3px;
      z-index: 10;
      .stopCourse {
        width: 200px;
        height: 60px;
        border-radius: 42px;
        color: #fff;
        background: #2cbd74;
        line-height: 60px;
        font-size: 24px;
        cursor: pointer;
        text-align: center;
      }
      .contOp {
        width: 200px;
        height: 60px;
        border: 1px solid #ccc;
        border-radius: 42px;
        color: #ccc;
        line-height: 60px;
        font-size: 24px;
        text-align: center;
      }
      .cancel {
        width: 200px;
        height: 60px;
        border: 1px solid #2cbd74;
        border-radius: 42px;
        color: #2cbd74;
        line-height: 60px;
        font-size: 24px;
        cursor: pointer;
        text-align: center;
      }
      .sure {
        width: 200px;
        height: 60px;
        background: #dc5310;
        border-radius: 42px;
        color: #fffcfd;
        line-height: 60px;
        font-size: 24px;
        cursor: pointer;
        text-align: center;
      }
      .isClassOver {
        width: 200px;
        height: 60px;
        background: #2cbd74;
        border-radius: 42px;
        color: #fffcfd;
        line-height: 60px;
        font-size: 24px;
        cursor: pointer;
        text-align: center;
      }
    }
  }
}
/deep/ .el-dialog__headerbtn {
  display: none;
}
</style>

