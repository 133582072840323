import * as types from './../mutation-types';
const message = {
    state: {
        isConnected: false,
        message: {
            finishClass: 0, // 0=> 不处理 1=> 弹框
        },
        reconnectError: false
    },
    mutations: {
        SETMESSAGE(state, params) {
            state.message.finishClass = params;
        },
        [types.SOCKET_ONOPEN](state) {
            console.log('websocket connected');
            state.isConnected = true;
            state.reconnectError = false;
        },
        [types.SOCKET_ONCLOSE](state) {
            console.log('websocket closed');
            state.isConnected = false;
        },
        [types.SOCKET_ONERROR](state, event) {
            console.error('on error:', event);
            state.reconnectError = true;
        },
        // default handler called for all methods
        [types.SOCKET_ONMESSAGE](state, mes) {
            console.log(mes,'wsssss')
            state.message.finishClass = mes.finishClass;
        },
        [types.SOCKET_RECONNECT](state, count) {
            console.info(state, count);
        },
        [types.SOCKET_RECONNECT_ERROR](state) {
            state.reconnectError = true;
        }
    }
};

export default message;
