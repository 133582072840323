import { Post, Get, Put } from '@/http/http';
// import http from '@/http';

export default {
    /* 获取排课Id */
    getCoursePaikeId: async(overClass, cb) => {
        const response = await Get(`teacher-managerment/tblClassScheduling/getClassSchedulingId?overClass=${overClass}`, {}, res => {
            cb && cb(res);
        });
        return response;
    },
    /* 跳转确认 */
    goToPage(id, cb) {
        Get(`teacher-managerment/aflpClassTeacherSignIn/goToPage/${id}`, {}, res => {
            cb(res);
        });
    },
    /* 老师签到 */  
    teacherSignIn(params, cb) {
        Post('teacher-managerment/aflpClassTeacherSignIn/signIn', params, res => {
            cb(res);
        });
    },
    /* 获取签到时间 */
    getCurrDayLatestClassTime: async(params, cb) => {
        const response = await Get('teacher-managerment/tblClassScheduling/getCurrDayLatestClass', params, res => {
            cb && cb(res);
        });
        return response;
    },
    /* 获取当前上课的课程信息 */
    getCurrDayOnClass: async(params, cb) => {
        const response = await Get('teacher-managerment/tblClassScheduling/getCurrDayOnClass', params, res => {
            cb && cb(res);
        });
        return response;
    },
     /* 确认学生到弃，开始上课 */
     aflpClassTeacherSignInEnsure(id, cb) {
        Put(`teacher-managerment/aflpClassTeacherSignIn/ensure/${id}`, {}, res => {
            cb(res);
        });
    },
    /* 获取学员列表 */
    getStudentListInStudy: async(id, cb) => {
        const response = await Get(`teacher-managerment/tblStudentCourseClass/getStudentListInStudy/${id}`, {}, res => {
            cb(res);
        });
        return response;
    },
    /* 学生签到 */  
    studentSignIn: async(params, cb) => {
        const response = await Post('teacher-managerment/aflpClassStudentSignIn/signIn', params, res => {
            cb && cb(res);
        });
        return response;
    },
    /* 获取细则列表 */
    getScoringItemList: async(id, cb) => {
        const response = await Get(`teacher-managerment/aflpCourseTimeSetting/getScoringItemList/${id}`, {}, res => {
            cb(res);
        });
        return response;
    },
    /* 实时详情 */
    getScoreList: async(id, cb) => {
        const response = await Get(`teacher-managerment/aflpStudentScore/getScoreList/${id}`, {}, res => {
            cb(res);
        });
        return response;
    },
    /* 结束上课 */
    overClass: async(id, cb) => {
        const response = await Put(`teacher-managerment/aflpClassTeacherSignIn/overClass/${id}`, {}, res => {
            cb(res);
        });
        return response;
    },
     /* 学员评分 */  
     aflpStudentScoreMark: async(params, cb) => {
        const response = await Post('teacher-managerment/aflpStudentScore/mark', params, res => {
            cb && cb(res);
        });
        return response;
    },
    /* 学员取消签到 */  
    aflpClassStudentSignInCancel: async(params, cb) => {
        const response = await Post('teacher-managerment/aflpClassStudentSignIn/cancel', params, res => {
            cb && cb(res);
        });
        return response;
    },
    /* 解锁 */  
    aflpUnLock: async(params, cb) => {
        const response = await Post('teacher-managerment/aflpLock/unLock', params, res => {
            cb && cb(res);
        });
        return response;
    },
    
    /* 顺延调课 */  
    emptyClass: async(params, cb) => {
        const response = await Put('teacher-managerment/aflpClassTeacherSignIn/emptyClass', params, res => {
            cb && cb(res);
        });
        return response;
    },
    /* 根据paikeId查询课程信息 */
    getCourseInfoByPaikeId: async(id, cb) => {
        const response = await Get(`teacher-managerment/tblClassScheduling/getClassSchedulingDetail/${id}`, {}, res => {
            cb(res);
        });
        return response;
    },

    //课堂重点相关

    /*查询课堂重点 */
    aflpClassFocusRecord: async (id,cb) => {
        const response = await Get(`teacher-managerment/aflpClassFocus/${id}`, {}, res => {
            cb(res);
        });
        return response;
    },
    /* 新增课堂重点 */  
    aflpUnLockAdd: async(params, cb) => {
        const response = await Post('teacher-managerment/aflpClassFocus', params, res => {
            cb && cb(res);
        });
        return response;
    },
    /* 删除课堂重点 */  
    aflpClassFocusDelete: async(id, cb) => {
        const response = await Get(`teacher-managerment/aflpClassFocus/delete/${id}`, {}, res => {
            cb(res);
        });
        return response;
    },
    /* 课堂重点排序 */  
    aflpUnLockSort: async(params, cb) => {
        const response = await Put('teacher-managerment/aflpClassFocus/sort', params, res => {
            cb && cb(res);
        });
        return response;
    },

    // 拓展课相关
    
    /*查询拓展课资源库列表 */
    appletExpandResourcesCourseList: async (courseId,resourcesName,schedulingId,cb) => {
        const response = await Get(`teacher-managerment/appletExpandCourse/get?courseId=${courseId}&name=${resourcesName}&schedulingId=${schedulingId}`, {}, res => {
            cb(res);
        });
        return response;
    },
    /*查询拓展课列表 */
    aflpExpandCourseList: async (schedulingId,cb) => {
        const response = await Get(`teacher-managerment/aflpExpandCourse/${schedulingId}`, {}, res => {
            cb(res);
        });
        return response;
    },
    /*删除拓展课 */
    aflpExpandCourseDelete: async (id,cb) => {
        const response = await Get(`teacher-managerment/aflpExpandCourse/delete/${id}`, {}, res => {
            cb(res);
        });
        return response;
    },
    /*排序拓展课 */
    aflpExpandCourseSort: async (params,cb) => {
        const response = await Put('teacher-managerment/aflpExpandCourse/sort', params, res => {
            cb(res);
        });
        return response;
    },
    /*更新拓展课 */
    aflpExpandCourseUpdata: async (params,cb) => {
        const response = await Put('teacher-managerment/aflpExpandCourse', params, res => {
            cb(res);
        });
        return response;
    },

};