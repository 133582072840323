<template>
  <div id="main" class="app-main">
    <router-view  />
  </div>
</template>
<script>
import Cookies from 'js-cookie';
export default {
 data () {
   return {
    //  isRouterAlive: true
      gap_time: null,
      beforeUnload_time: null
   }
 },
 mounted(){
  //  document.addEventListener('keydown', function (event) {
  //       if ((event.ctrlKey === true || event.metaKey === true)
  //           && (event.which === 61 || event.which === 107
  //               || event.which === 173 || event.which === 109
  //               || event.which === 187 || event.which === 189)) {
  //           event.preventDefault();
  //       }
  //   }, false);
    // Chrome IE 360
    // window.addEventListener('mousewheel', function (event) {
    //     if (event.ctrlKey === true || event.metaKey) {
    //         event.preventDefault();
    //     }
    // }, { passive: false });

    // //firefox
    // window.addEventListener('DOMMouseScroll', function (event) {
    //     if (event.ctrlKey === true || event.metaKey) {
    //         event.preventDefault();
    //     }
    // }, { passive: false });
    window.addEventListener('beforeunload',()=>{
        this.beforeUnload_time = new Date().getTime();
    });
    window.addEventListener('unload',()=>{   
        this.gap_time = new Date().getTime() - this.beforeUnload_time;
        if(this.gap_time <= 5){
           localStorage.clear();
           Cookies.remove('last_page_name');
           Cookies.remove('locking');
        } 
    });
    // 监听页面是否隐藏
    document.addEventListener('visibilitychange', () => {
      this.$store.commit('SET_DOCUMENT_VISIBILITY', document.hidden ? false : true);
    });
 },
 methods: {
  
 }
}
</script>
<style lang="scss">
html,body{
    width: 100%;
    height: 100%;
    background: #f0f0f0;
    margin: 0;
    overflow: hidden;
}
.app-main{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
