/* eslint-disable no-extra-boolean-cast */
/**
 * 作用：负责发送ajax请求
 */
import Config from './config';
import axios from 'axios';
import store from '@/store/index';
import { getStore } from '../util';
import { Message } from 'element-ui';
import { Notification } from 'element-ui';

var instance = axios.create(Config.httpConfig);

// 请求拦截
instance.interceptors.request.use(config => {
    return config;
});

// 响应拦截
instance.interceptors.response.use(
    response => {
        let status = response.data.status;
        if(status === 401) {
            Notification({
                title: '用户信息已过期，请重新登陆~',
                type: 'success',
                duration: 1500,
              });
              setTimeout(() => {
                reLogin();
            },1500)
            Promise.reject(response);
        }else if(status === 500) {
            console.error(response.data.message ? response.data.message : '');
            response.data = {status: -1, message: '网络故障了，请稍后再试~'};
            return response;
        }else {
            return response;
        }
    },
    err => {
        if(err && err.response) {
            switch(err.response.status) {
                case 400:
                    err.message = '请求错误';
                    break;
                case 401:
                    err.message = '未授权，请登录';
                    Notification({
                        title: '用户信息已过期，请重新登陆~',
                        type: 'success',
                        duration: 1500,
                      });
                      setTimeout(() => {
                        reLogin();
                    },1500)
                    break;

                case 403:
                    err.message = '拒绝访问';
                    break;

                case 404:
                    err.message = `请求地址出错: ${err.response.config.url}`;
                    break;

                case 408:
                    err.message = '请求超时';
                    break;

                case 500:
                    err.message = '服务器内部错误';
                    break;

                case 501:
                    err.message = '服务未实现';
                    break;

                case 502:
                    err.message = '网关错误';
                    break;

                case 503:
                    err.message = '服务不可用';
                    break;

                case 504:
                    err.message = '网关超时';
                    break;

                case 505:
                    err.message = 'HTTP版本不受支持';
                    break;

                default:
            }
        }
        Message.error('网络故障了，请稍后再试~');
        // return Promise.reject(err);
    }
);

/**
 * read user token from localstorage
 */
function readToken() {
    const token = getStore('token');
    return (!!token) ? token : '';
}

function reLogin() {
    store.dispatch('logout');
}

class Http {
    constructor() {
        // initialize user's token from localstorage
        this.token = readToken();
    }

    updateToken() {
        return readToken();
    }

    // 遍历请求参数中所有开头/结尾有空格的字段,并去除
    toTrim(data) {
        if(data === null) return null;
        else if(typeof data === 'string') return data.trim();
        else if(data !== null && typeof data === 'object') {
            if(Object.prototype.toString.call(data) === '[object Object]') {
                let newObj = data instanceof Array ? [] : {};
                for(let key in data) {
                    newObj[key] = this.toTrim(data[key]);
                }
                return newObj;
            }
            return data;
        }else return data;
    }

    request({ url, method, headers, data, timeout = Config.httpConfig.timeout, ...rest }) {
        let newData = this.toTrim(data);
        const self = this;
        this.token = this.updateToken();
        // merge headers with customer defined ones
        headers = Object.assign({}, { Authorization: self.token }, headers);
        let references;
        if(method === 'GET' || method === 'DELETE') {
            references = { params: newData };
        }else if(method === 'POST' || method === 'PUT') {
            references = { data: newData };
        }
        // intercept mock request in env which is not 'development'
        const { baseURL } = rest;
        if(!baseURL && /^(\/mock\/)/.test(url) && process.env.NODE_ENV !== 'development') return Promise.resolve({data: null});
        // adjust the request method, in order to refer data by different ways
        return instance.request({
            method,
            url,
            headers,
            timeout,
            ...references,
            ...rest
        });
    }
}

export default new Http();
