<template>
    <div class="nowTime">
        <span>{{nowTime}}</span>
        <span class="strDate">{{nowDate}}</span>
    </div>
</template>

<script>
    export default {
        name: 'NetTime',
        props: {
            currentTime: {
                type: Number,
                default: null
            },
        },
        data() {
            return {
                currentTimeI: '',
                nowTime: '',
                nowDate: '',
                timer: null,
            }
        },
        watch: {
            currentTime(newValue, oldValue) {
                if(this.timer) { //如果定时器在运行则关闭
                    clearInterval(this.timer); 
                }
                this.currentTimeI = newValue;
                this.getNowTime()
            },
        },
        methods: {
            getNowTime(){
                if(this.currentTimeI){
                    let timestamp = Number(this.currentTimeI)*1000;
                    this.timer = setInterval(() => {
                        timestamp=timestamp + 1000 
                        this.caluTime(timestamp)
                    }, 1000);
                }else{
                    this.nowTime = null
                    this.nowDate = null
                }
            },
            caluTime(time){
                if(time){
                    var date = new Date(time);
                    var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '月';
                    var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + '日';
                    var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
                    var m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
                    var s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
                    let strTime = h+m+s
                    let strDate = M+D;
                    this.nowTime = strTime
                    this.nowDate = strDate
                }else{
                    this.nowTime = null
                    this.nowDate = null
                }
                
            },
        },
    };
</script>
<style lang="less" scoped>
   .nowTime{
       font-size: 26px;
       color: #fff;
       margin-right: 20px;
   }
   .strDate{
       display: inline-block;
       margin-left: 30px;
   }
</style>
