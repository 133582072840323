<style lang="less">
    @import "./main.less";
</style>
<template>
    <div class="main" v-loading.fullscreen.lock="fullscreenLoading">
        <div class="main-header-con" v-if="reloadHeard">
            <div class="main-header">
                <div class="header-coouse-class">
                        <el-row 
                            type="flex" 
                            align="middle" 
                            class="user-dropdown-innercon">
                             <courseClassInfo :courseName="courseName" :classCode="classCode" :isMusic="isMusic" :paikeId="paikeId"></courseClassInfo>
                        </el-row>
                </div>
                <div class="header-avator-con">
                        <el-row 
                            type="flex" 
                            justify="end" 
                            align="middle" 
                            class="user-dropdown-innercon">
                             <netTime :currentTime="currentTime"></netTime>
                             <el-avatar :src="avatorPath" style="background: #2CBD74;margin-left: 10px;"></el-avatar>
                             <span class="main-user-name">{{ userName }}</span>
                             <lock-screen></lock-screen>
                             <signOut :paikeId="paikeId"></signOut>
                             <full-screen v-model="isFullScreen"></full-screen>
                        </el-row>
                </div>
            </div>
        </div>
        <div class="single-page-con">
            <div class="single-page">
                <div class="overClassTip" v-if="overClassTip">
                    <div class="bell" >
                        <img :src='bell' alt="铃铛" class="bellImg">
                    </div>
                    <div class="content">
                        <div class="tipOne">亲，课程结束后</div>
                        <div class="tipTwo">记得点下课哦~</div>
                    </div>
                    <div class="footer">
                        <div class="button" @click="tipHidden">
                            好的
                        </div>
                    </div>
                </div>
                <transition 
                    name="slide-fade" 
                    mode="out-in">
                    <router-view></router-view>
                </transition>
            </div>
        </div>
    </div>
</template>
<script>
    import { Message } from 'element-ui';
    import { page } from './../api/index.js';
    import lockScreen from './main-components/lockscreen/lockscreen.vue';
    import fullScreen from './main-components/fullscreen';
    import signOut from './main-components/signOut';
    import courseClassInfo from './main-components/courseClassInfo';
    import netTime from './main-components/netTime';
    export default {
        components: {
            lockScreen,
            signOut,
            fullScreen,
            courseClassInfo,
            netTime
        },
        data() {
            return {
                isFullScreen: false,
                reloadHeard: true,
                dialogVisible: true,
                bell: require('../assets/image/overClassTip.png'),
                overClassTip: false,
                fullscreenLoading: false,
                currentTime: null,
                courseName: '',
                classCode: '',
                infoId: '',
                isMusic: 0,
                overClass: this.$route.query.overClass ? 1 : 0,
                paikeId: '',
            };
        },
        computed: {
            nowClassCode(){
                return this.$store.state.user.classCode ;
            },
            userName() {
                return this.$store.state.user.name ;
            },
            currentRole() {
                return this.$store.state.user.currentRole;
            },
            avatorPath() {
                let avatorPath = '';
                if(localStorage.YZYX_CRM_gender === '1'){
                    avatorPath = require('../assets/image/girlAva.png')
                }else{
                    avatorPath = require('../assets/image/boyAva.png')
                }
                return avatorPath
            },
            finishClass() {
                return this.$store.getters.finishClass;
            },
            docmentVisibility() {
                return this.$store.state.user.docmentVisibility;
            },
        },
        watch: {
            nowClassCode(val){
                if(val){
                    if(this.overClass){
                        this.getCoursePaikeId(1);
                    }else{
                        this.getCoursePaikeId('');
                    }
                    // this.getPaikeIdForQuery().then((res) => {
                    //     this.getBasicInfo(res)
                    // }).catch(() => {
                    //     this.getPaikeId().then((res) => {
                    //         this.getBasicInfo(res)
                    //     }).catch(() => {
                    //         this.getPaikeIdForCurrDayLatestClass().then((res) => {
                    //             this.getBasicInfo(res)
                    //         }).catch((res) => {
                    //             this.currentTime = res;
                    //         });
                    //     });
                    // })
                }
            },
            finishClass(val){
                if(val){
                    this.overClassTip = true;
                }
            },
            docmentVisibility(newVal) {
                if (!newVal) return;
                if(this.overClass){
                    this.getCoursePaikeId(1);
                }else{
                    this.getCoursePaikeId('');
                }
                // this.getPaikeIdForQuery().then((res) => {
                //     this.getBasicInfo(res)
                // }).catch(() => {
                //     this.getPaikeId().then((res) => {
                //         this.getBasicInfo(res)
                //     }).catch(() => {
                //         this.getPaikeIdForCurrDayLatestClass().then((res) => {
                //             this.getBasicInfo(res)
                //         }).catch((res) => {
                //             this.currentTime = res;
                //         });
                //     });
                // })
            },
        },
        mounted() {
            if(this.overClass){
                this.getCoursePaikeId(1);
            }else{
                this.getCoursePaikeId('');
            }
            // this.getPaikeIdForQuery().then((res) => {
            //     this.getBasicInfo(res)
            // }).catch(() => {
            //     this.getPaikeId().then((res) => {
            //         this.getBasicInfo(res)
            //     }).catch(() => {
            //         this.getPaikeIdForCurrDayLatestClass().then((res) => {
            //             this.getBasicInfo(res)
            //         }).catch((res) => {
            //             this.currentTime = res;
            //         });
            //     });
            // })
        },
        methods: {
            async getCoursePaikeId(val){
                await page.getCoursePaikeId(val, async res => {
                    if(res.status === 1) {
                        if(res.data && res.data.courseName){
                            if(!res.data.scheduleId){
                                Message.error(`排课ID获取失败，请不要操作，并联系管理员！ + ${JSON.stringify(res.data)}`);
                            }
                            this.paikeId = res.data.scheduleId;
                            this.courseName = res.data.courseName;
                            this.currentTime = res.data.currentTime;
                            this.classCode = res.data.classCode;
                            this.infoId = res.data.infoId;
                            this.isMusic = res.data.isMusic;
                            await this.$connect();
                            this.$options.sockets.onopen = this.sendOpenMsg;
                        }else{
                                this.currentTime = res.data;
                            }
                    }else {
                        Message.error(res.message);
                    }
                });
            },
            // async getBasicInfo(res){
            //     this.paikeId = res.data.paikeId;
            //     this.courseName = res.data.courseName;
            //     this.currentTime = res.data.currentTime;
            //     this.classCode = res.data.classCode;
            //     this.infoId = res.data.infoId;
            //     this.isMusic = res.data.isMusic;
            //     await this.$connect();
            //     this.$options.sockets.onopen = this.sendOpenMsg;
            // },
            // getPaikeId(){
            //     return new Promise(function(resolve,reject){
            //         page.getCurrDayOnClass({}, res => {
            //             if(res.status === 1) {
            //                 if(res.data && res.data.courseName){
            //                     resolve(res)
            //                 }else{
            //                     reject()
            //                 }   
            //             }else {
            //                 Message.error(res.message);
            //             }
            //         });
            //     })
            // },
            // getPaikeIdForCurrDayLatestClass(){
            //     return new Promise(function(resolve,reject){
            //         page.getCurrDayLatestClassTime({}, res => {
            //             if(res.status === 1) {
            //                 if(res.data && res.data.courseName){
            //                     resolve(res)
            //                 }else{
            //                     reject(res.data)
            //                 }
            //             }else {
            //                 Message.error(res.message);
            //             }
            //         });
            //     })
            // },
            // getPaikeIdForQuery(){
            //     const that = this;
            //     return new Promise((resolve,reject)=>{
            //         if(!that.$route.query.paikeId){
            //             reject()
            //         }else{
            //             page.getCourseInfoByPaikeId(that.$route.query.paikeId, res => {
            //                 if(res.status === 1) {
            //                     if(res.data && res.data.courseName){
            //                         resolve(res)
            //                     }else{
            //                         reject()
            //                     }
            //                 }else {
            //                     Message.error(res.message);
            //                 }
            //             });
            //         }
            //     })
            // },
            sendOpenMsg() {
                this.$socket.sendObj({
                    token: '',
                    roleId: '',
                    userId: this.$store.getters.userId,
                    scheduleId: this.paikeId ? this.paikeId : '',
                    teacherId: this.$store.getters.teacherId,
                    system: 'aflp',
                    command: 2
                });
            },
            tipHidden(){
                this.overClassTip = false;
                this.$store.commit('SETMESSAGE', 0);
            },
        }
    };
</script>
<style lang="less" scoped>
.drop-down-menu{
    .ivu-icon{
        font-size: 14px;
    }
}
.header-avator-con{
    .ivu-icon{
        font-size: 20px;
    }
    .main-user-name{
        display: inline-block;
        font-size: 24px;
        font-family: Noto Sans S Chinese;
        font-weight: 400;
        color: #FFFCFD;
        margin-left: 15px;
        margin-right: 30px;
    }
    .ivu-icon-md-arrow-dropdown{
        font-size: 16px;
    }
}

.slide-fade-enter-active {
    transition: all .3s ease;
}
.slide-fade-enter,.slide-fade-leave-to
{
    transform: translateX(30px);
    opacity: 0;
}
/deep/ .el-avatar{
    width: 60px;
    height: 60px;
    line-height: 60px;
}
.overClassTip{
    width: 220px;
    height: 255px;
    z-index: 10000000000000;
    position: absolute;
    right: 0;
    background: #FFFFFF;
    opacity: 0.85;
    border-radius: 0 0 20px 20px;
    overflow: hidden;
}
.bell{
    width: 100%;
    height: 70px;
    text-align: center;
    margin-top: 10px;
    .bellImg{
        width: 50px;
        height: 70px;
    }
}
.content{
    width: 100%;
    height: 80px;
    font-size: 20px;
    font-family: Noto Sans S Chinese;
    font-weight: 400;
    color: #211F1F;
    line-height: 34px;
    letter-spacing: 5px;
    .tipOne{
        width: 100%;
        text-align: center;
        margin: 20px 0 10px 0;
    }
    .tipTwo{
        width: 100%;
        text-align: center;
    }
}
.footer{
        width: 100%;
        height: 60px;
        
    }
.button{
    width: 100px;
    height: 35px;
    border: 2px solid #2CBD74;
    border-radius: 25px;
    color: #2CBD74;
    font-size: 18px;
    line-height: 35px;
    text-align: center;
    margin: 20px 0 0 60px;
    cursor: pointer;
}
</style>
