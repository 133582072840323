import { Post } from '@/http/http';
// import http from '@/http';

export default {
    /* 用户登录 */
    login(params, cb) {
        Post('auth/aflp/login', params, res => {
            cb(res);
        });
    },
    /* 用户登出 */  
    logout(params, cb) {
        Post('auth/logout', params, res => {
            cb(res);
        });
    },
    /* 重置密码 */  
    resetPassWord: async(jobNo, cb) => {
        const response = await Post(`teacher-managerment/aflpScoringItem/resetPassword/${jobNo}`, {}, res => {
            cb && cb(res);
        });
        return response;
    },
};
