const getters = {
    // 用户 name
    name: state => state.user.name,
    // 用户token
    token: state => state.user.token,
    // 用户当前角色
    currentRole: state => state.user.currentRole,
    // userId
    userId: state => state.user.userId,
    // 当前课程
    courseName: state => state.user.courseName,
    // 班级代码
    classCode: state => state.user.classCode,
    // 是否全屏
    isFullscreen: state => state.user.isFullscreen,
    // ws消息
    finishClass: state => state.message.message.finishClass,
    // 排课Id
    paikeId: state => state.user.paikeId,
    // teacherId
    teacherId: state => state.user.teacherId,
};

export default getters;