<template>
    <div 
        class="lock-screen-btn-con" 
        @click="lockScreen">
        <el-tooltip 
            content="锁屏" 
            placement="bottom">
            <i class="el-icon-lock lockIcon"></i>
        </el-tooltip>
    </div>
</template>

<script>
    import Cookies from 'js-cookie';
    const setLockBackSize = () => {
        let x = document.body.clientWidth;
        let y = document.body.clientHeight;
        let r = Math.sqrt(x * x + y * y);
        return parseInt(r);
    };
    export default {
        name: 'LockScreen',
        props: {
            value: {
                type: Boolean,
                default: false
            }
        },
        mounted() {
            let lockScreenBack;
            if(!document.getElementById('lock_screen_back')) {
                let lockdiv = document.createElement('div');
                lockdiv.setAttribute('id', 'lock_screen_back');
                lockdiv.setAttribute('class', 'lock-screen-back');
                document.body.appendChild(lockdiv);
                lockScreenBack = document.getElementById('lock_screen_back');
                window.addEventListener('resize', () => {
                    let size = setLockBackSize();
                    this.lockScreenSize = size;
                    lockScreenBack.style.transition = 'all 0s';
                    lockScreenBack.style.width = lockScreenBack.style.height = size + 'px';
                });
            }else {
                lockScreenBack = document.getElementById('lock_screen_back');
            }
            let size = setLockBackSize();
            this.lockScreenSize = size;
            lockScreenBack.style.transition = 'all 3s';
            lockScreenBack.style.width = lockScreenBack.style.height = size + 'px';
        },
        methods: {
            lockScreen() {
                let lockScreenBack = document.getElementById('lock_screen_back');
                lockScreenBack.style.transition = 'all 3s';
                lockScreenBack.style.zIndex = 10000;
                lockScreenBack.style.boxShadow = '0 0 0 ' + this.lockScreenSize + 'px #2CBD74 inset';
                this.showUnlock = true;
                Cookies.set('last_page_name', this.$route.name); // 本地存储锁屏之前打开的页面以便解锁后打开
                setTimeout(() => {
                    lockScreenBack.style.transition = 'all 0s';
                    let obj = this.$route.query.paikeId ? { name: 'locking', query: {paikeId: this.$route.query.paikeId} } : { name: 'locking' }
                    this.$router.push(obj);
                }, 800);
                Cookies.set('locking', '1');
            }
        },
    };
</script>
<style lang="less" scoped>
   .lockIcon{
       display: inline-block;
       font-size: 30px;
       color: #fff;
   }
</style>

